import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "RabbitMQ as MQTT Broker for your WQHD INSTAR Camera",
  "path": "/Frequently_Asked_Question/RabbitMQ_as_MQTT_Broker_INSTAR_WQHD/",
  "dateChanged": "2023-03-16",
  "author": "Mike Polinowski",
  "excerpt": "I am using RabbitMQ in my smarthome and want to connect my INSTAR 2k+ WQHD IP MQTT camera.",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='RabbitMQ as MQTT Broker for your WQHD INSTAR Camera' dateChanged='2023-03-16' author='Mike Polinowski' tag='INSTAR IP Camera' description='I am using RabbitMQ in my smarthome and want to connect my INSTAR 2k+ WQHD IP MQTT camera.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/RabbitMQ_as_MQTT_Broker_INSTAR_WQHD/' locationFR='/fr/Frequently_Asked_Question/RabbitMQ_as_MQTT_Broker_INSTAR_WQHD/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    
    <h1 {...{
      "id": "rabbitmq-as-mqtt-broker-for-your-wqhd-instar-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#rabbitmq-as-mqtt-broker-for-your-wqhd-instar-camera",
        "aria-label": "rabbitmq as mqtt broker for your wqhd instar camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`RabbitMQ as MQTT Broker for your WQHD INSTAR Camera`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I am using RabbitMQ in my smarthome and want to connect my INSTAR 2k+ WQHD IP MQTT camera.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: I tested this by setting up the latest `}<a parentName="p" {...{
        "href": "https://github.com/mpolinowski/rabbitmq-mqtt-ws-docker"
      }}>{`RabbitMQ (alpine-management) Docker Image`}</a>{` where I included the MQTT Adapter:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d0f39fa14d4dc5af87800956771db7a4/525d3/rabbitmq_mqtt_ws_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "19.565217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAh0lEQVQI12NQUFBTVtKQk1WWk1GSlVaQl1eRl1eRlJCVl5CUlZCSlZaXkpQTEZGEIDExaVlZJVkZJVVVbRVlTQasgJOFwViUUZKVQVKAn4ubh4F4wMTIxMHFZWthXZKenZiYmpudr6kBsoSRiYmwZkZGRgYGBiVFJS0NDQEBfjExUXZ2dqxKAS+RDh0WDxokAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d0f39fa14d4dc5af87800956771db7a4/e4706/rabbitmq_mqtt_ws_01.avif 230w", "/en/static/d0f39fa14d4dc5af87800956771db7a4/d1af7/rabbitmq_mqtt_ws_01.avif 460w", "/en/static/d0f39fa14d4dc5af87800956771db7a4/7f308/rabbitmq_mqtt_ws_01.avif 920w", "/en/static/d0f39fa14d4dc5af87800956771db7a4/e726d/rabbitmq_mqtt_ws_01.avif 1090w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d0f39fa14d4dc5af87800956771db7a4/a0b58/rabbitmq_mqtt_ws_01.webp 230w", "/en/static/d0f39fa14d4dc5af87800956771db7a4/bc10c/rabbitmq_mqtt_ws_01.webp 460w", "/en/static/d0f39fa14d4dc5af87800956771db7a4/966d8/rabbitmq_mqtt_ws_01.webp 920w", "/en/static/d0f39fa14d4dc5af87800956771db7a4/36bbb/rabbitmq_mqtt_ws_01.webp 1090w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d0f39fa14d4dc5af87800956771db7a4/81c8e/rabbitmq_mqtt_ws_01.png 230w", "/en/static/d0f39fa14d4dc5af87800956771db7a4/08a84/rabbitmq_mqtt_ws_01.png 460w", "/en/static/d0f39fa14d4dc5af87800956771db7a4/c0255/rabbitmq_mqtt_ws_01.png 920w", "/en/static/d0f39fa14d4dc5af87800956771db7a4/525d3/rabbitmq_mqtt_ws_01.png 1090w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d0f39fa14d4dc5af87800956771db7a4/c0255/rabbitmq_mqtt_ws_01.png",
              "alt": "RabbitMQ as MQTT Broker for your WQHD INSTAR Camera",
              "title": "RabbitMQ as MQTT Broker for your WQHD INSTAR Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`I am able to connect to the server using a Debug Tool like the MQTT Explorer:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/75a564c3f9bcc633f7492de999662ec0/891d5/rabbitmq_mqtt_ws_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABr0lEQVQoz4XPTa/SQBgF4OnXtJMOcIHeCthpK1BtCxQwaizXltJh2hFKUowLF+7deMMeE3f+b0OjO3N9ct6zO4sXvOhIvbYOVVUURQgVpGkYt1x36r+aOa7vR2m43K7eFau3zF+kfxLdEq4yYJm9IAjanY4sS+AvhDTbtp7bvuNR16PO9Nb2lDle4XiF+7KcBHzsc9C/N/v9PkKaIIAmoiCA2wHQ7rTDMJzNwjAMAt9fLObr9Wq5ipbLaD6fTSZj0NGxLMkI6QjpiqIIggAhVBRZkaFju+fzua7rqqo45+fzp7Is94zVdb3ZbCzLAtu7uwHGSMctjBFCmqYhhDDWJUkkhFRVtWvkeV5VVcFYnuen0ymOY9u2QQshrOsqhLIkKXJDkiCEAABCyPF45JwfDgfG2G6XU7ovi5KxIo4fxmMP/JPQ/EwIKYriQyNJko+c5zRL6JYX2UOyI074n/H1er1cLj+u1++Pj2zPfiWfv2Vfqy8/k01pmr2nxt1ul1KaZRljLE3TKIqot3jvv56v0zfuZDA0wRNUVTUMo98wDGM0Ghkj0xzeDwbGcPiMEOs3+wxLidlt9i8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/75a564c3f9bcc633f7492de999662ec0/e4706/rabbitmq_mqtt_ws_02.avif 230w", "/en/static/75a564c3f9bcc633f7492de999662ec0/d1af7/rabbitmq_mqtt_ws_02.avif 460w", "/en/static/75a564c3f9bcc633f7492de999662ec0/7f308/rabbitmq_mqtt_ws_02.avif 920w", "/en/static/75a564c3f9bcc633f7492de999662ec0/e1c99/rabbitmq_mqtt_ws_02.avif 1380w", "/en/static/75a564c3f9bcc633f7492de999662ec0/34947/rabbitmq_mqtt_ws_02.avif 1520w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/75a564c3f9bcc633f7492de999662ec0/a0b58/rabbitmq_mqtt_ws_02.webp 230w", "/en/static/75a564c3f9bcc633f7492de999662ec0/bc10c/rabbitmq_mqtt_ws_02.webp 460w", "/en/static/75a564c3f9bcc633f7492de999662ec0/966d8/rabbitmq_mqtt_ws_02.webp 920w", "/en/static/75a564c3f9bcc633f7492de999662ec0/445df/rabbitmq_mqtt_ws_02.webp 1380w", "/en/static/75a564c3f9bcc633f7492de999662ec0/7afe4/rabbitmq_mqtt_ws_02.webp 1520w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/75a564c3f9bcc633f7492de999662ec0/81c8e/rabbitmq_mqtt_ws_02.png 230w", "/en/static/75a564c3f9bcc633f7492de999662ec0/08a84/rabbitmq_mqtt_ws_02.png 460w", "/en/static/75a564c3f9bcc633f7492de999662ec0/c0255/rabbitmq_mqtt_ws_02.png 920w", "/en/static/75a564c3f9bcc633f7492de999662ec0/b1001/rabbitmq_mqtt_ws_02.png 1380w", "/en/static/75a564c3f9bcc633f7492de999662ec0/891d5/rabbitmq_mqtt_ws_02.png 1520w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/75a564c3f9bcc633f7492de999662ec0/c0255/rabbitmq_mqtt_ws_02.png",
              "alt": "RabbitMQ as MQTT Broker for your WQHD INSTAR Camera",
              "title": "RabbitMQ as MQTT Broker for your WQHD INSTAR Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a214174d889266e1f6df5e00fe51fc66/68947/rabbitmq_mqtt_ws_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAABPElEQVQY032OW0sCURRGz3Vu536mGXsKlaJM60xnlPBBrRSzpChCMujnqb32E0Ml6iFbLNjshwUfOOCwxoFhcRjHlFIIYZIkYRhIKWvVw0bTd68eLy5HW8vuuOyOXWfYGz4Nxs8gE2E1142TY5umYRiCbyBELCFHpzeT+efkdTmeLUcvi4f3j7v56n6+up0tpm9LEMUsr+zX6nVtDMZ4k21BAACbZoPrqW/3fdnz7V7Z6f/Y7gPBhRACQUgwoYRSQjHGCCFCMIRQKVkUZ+68WbhW4Vrul4VrAc45Y5zSgDMphGIJw5s6iiKMUWptWZbOueIvwF6WSaWkVMoooaQyWimltMnzihDSpqn3vtgBUFpneW6s1Rttmhpj1u/6mDzL/ovjOJZSaCWlEBjjMAgwRgGlhKzXW2u897tmfwGZRj2fxd9EsAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a214174d889266e1f6df5e00fe51fc66/e4706/rabbitmq_mqtt_ws_03.avif 230w", "/en/static/a214174d889266e1f6df5e00fe51fc66/d1af7/rabbitmq_mqtt_ws_03.avif 460w", "/en/static/a214174d889266e1f6df5e00fe51fc66/7f308/rabbitmq_mqtt_ws_03.avif 920w", "/en/static/a214174d889266e1f6df5e00fe51fc66/3d2c6/rabbitmq_mqtt_ws_03.avif 1281w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a214174d889266e1f6df5e00fe51fc66/a0b58/rabbitmq_mqtt_ws_03.webp 230w", "/en/static/a214174d889266e1f6df5e00fe51fc66/bc10c/rabbitmq_mqtt_ws_03.webp 460w", "/en/static/a214174d889266e1f6df5e00fe51fc66/966d8/rabbitmq_mqtt_ws_03.webp 920w", "/en/static/a214174d889266e1f6df5e00fe51fc66/45a26/rabbitmq_mqtt_ws_03.webp 1281w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a214174d889266e1f6df5e00fe51fc66/81c8e/rabbitmq_mqtt_ws_03.png 230w", "/en/static/a214174d889266e1f6df5e00fe51fc66/08a84/rabbitmq_mqtt_ws_03.png 460w", "/en/static/a214174d889266e1f6df5e00fe51fc66/c0255/rabbitmq_mqtt_ws_03.png 920w", "/en/static/a214174d889266e1f6df5e00fe51fc66/68947/rabbitmq_mqtt_ws_03.png 1281w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a214174d889266e1f6df5e00fe51fc66/c0255/rabbitmq_mqtt_ws_03.png",
              "alt": "RabbitMQ as MQTT Broker for your WQHD INSTAR Camera",
              "title": "RabbitMQ as MQTT Broker for your WQHD INSTAR Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Adding the Broker to my camera's MQTT configuration it turned out to be `}<strong parentName="p">{`important to activate QoS1`}</strong>{` (instead of QoS2 - `}<em parentName="p">{`the screenshot below does not yet show this option. It will be added with the next update`}</em>{`):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/acfdc8dcc8ed41b2ede42aca77f939a7/01645/rabbitmq_mqtt_ws_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACCUlEQVQoz2WSy26bQABFraSyhFTAJnbMvMAGY5vXwDxhIHG7SqouEnXVb0jUqqv+fwVO7Kod3cVsjs7cq5mQIIAYI4x9HMKsW2z1TSz/zzJRq0R7t+jT8RhFEQDA98EEEQIQAhDAza5++pl9/ZE8vOweX8e8jHlNHl7Y86/++2+8LSpaBkHoj2cCMYYYrwCABDMptTEV55Qxytj5QhkruciF2R/2QRCEYRgQsvL9wfwOk0qIWiqmtDKdNh3lgkkl21bohnJJhcyLcr/bJUmyWCxc150MbSG89X1ISC2Vao0ynWzaSkjKRS0kU5opXQlFlanqOj0coiiaTqdXV1cThMkZ5rpp+zvd9XxQibyqoyRZxzEOQhSst2kRxfFqPLZtW5Y1dPYhOsGiaZv+TrXmpC0Zz/Ii2iabeLuO4n2WIUJc17Usy3Xdj7b9tvbZ3PR90/e1kCXnlZBcN6fI1oimDddrAMByuXQc5/r6wwAjjAFEJ3N3f2z6nnJxTiVkJeQ4ZDOfezeeBwBwHGc6nU7w+9qIEK607i5kyfg+y5M026VpcjikZTnzvNlsNp97lmUNMCIYkWHwAdZad90bLGRRVZtNCKF/i7CH0DrZrQC0HddxZ6eMnwS9menY82IWKjePqfliqHjMir6slj6wbecCX54dBCXjf7XlpdDF/VN2fD5S+S2nn8v6H/gPgAp1EW2KVOAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/acfdc8dcc8ed41b2ede42aca77f939a7/e4706/rabbitmq_mqtt_ws_04.avif 230w", "/en/static/acfdc8dcc8ed41b2ede42aca77f939a7/d1af7/rabbitmq_mqtt_ws_04.avif 460w", "/en/static/acfdc8dcc8ed41b2ede42aca77f939a7/7f308/rabbitmq_mqtt_ws_04.avif 920w", "/en/static/acfdc8dcc8ed41b2ede42aca77f939a7/8e468/rabbitmq_mqtt_ws_04.avif 1091w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/acfdc8dcc8ed41b2ede42aca77f939a7/a0b58/rabbitmq_mqtt_ws_04.webp 230w", "/en/static/acfdc8dcc8ed41b2ede42aca77f939a7/bc10c/rabbitmq_mqtt_ws_04.webp 460w", "/en/static/acfdc8dcc8ed41b2ede42aca77f939a7/966d8/rabbitmq_mqtt_ws_04.webp 920w", "/en/static/acfdc8dcc8ed41b2ede42aca77f939a7/49d62/rabbitmq_mqtt_ws_04.webp 1091w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/acfdc8dcc8ed41b2ede42aca77f939a7/81c8e/rabbitmq_mqtt_ws_04.png 230w", "/en/static/acfdc8dcc8ed41b2ede42aca77f939a7/08a84/rabbitmq_mqtt_ws_04.png 460w", "/en/static/acfdc8dcc8ed41b2ede42aca77f939a7/c0255/rabbitmq_mqtt_ws_04.png 920w", "/en/static/acfdc8dcc8ed41b2ede42aca77f939a7/01645/rabbitmq_mqtt_ws_04.png 1091w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/acfdc8dcc8ed41b2ede42aca77f939a7/c0255/rabbitmq_mqtt_ws_04.png",
              "alt": "RabbitMQ as MQTT Broker for your WQHD INSTAR Camera",
              "title": "RabbitMQ as MQTT Broker for your WQHD INSTAR Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Your camera should be listed under `}<strong parentName="p">{`Connections`}</strong>{` after a few seconds and start pushing out it's retained MQTT Status Topics:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d6a933c7963f40524dcbd9f46eadb419/844cc/rabbitmq_mqtt_ws_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAABc0lEQVQY012PzW6bQBSFLz8DMwzMMMwE17iu4k2XNQzUsSzAkqvGOEnBVhvbqhR10ceqbPUJ+oxRghSl+XR0d0ffPTAJYEIhjjj2qOM4NkI2QgBgGAYAjCef6vXvWX0sykNe7j9Xh6Lcz+pj9fXXrD6CoM6HdyrPc6kUwdj3fc/zsOu6GAPA5cdN9/CvuT992f25/n5qf/7d7M/N/nz949TcnyEU4fvxmHNuGE828xnLskzTBIDhMLn71hXFVZYV01RPU52+JMuBUkoIMQwT2ci2kWVZ/cP9lTLabbdt265Wq+WyTp+Y9tE6A98PMCaYEMYFY8L3A+Qg0zT75Uqp29ubruuqqprP5+krsiyDYTJScRxJJZQQMoqU5GEolUqShIVhHMfr9abb7p7Ny7flSMrhaBQKwQUPGGOcB5x5PmWc+0FwcaFu7g7t7qGqysViobX+r+w6Du5xXUIIpRRjTKlHMPY8MhgMmqbpN5dl+dqstX4ErvNDEXscoR4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d6a933c7963f40524dcbd9f46eadb419/e4706/rabbitmq_mqtt_ws_05.avif 230w", "/en/static/d6a933c7963f40524dcbd9f46eadb419/d1af7/rabbitmq_mqtt_ws_05.avif 460w", "/en/static/d6a933c7963f40524dcbd9f46eadb419/7f308/rabbitmq_mqtt_ws_05.avif 920w", "/en/static/d6a933c7963f40524dcbd9f46eadb419/ff569/rabbitmq_mqtt_ws_05.avif 1306w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d6a933c7963f40524dcbd9f46eadb419/a0b58/rabbitmq_mqtt_ws_05.webp 230w", "/en/static/d6a933c7963f40524dcbd9f46eadb419/bc10c/rabbitmq_mqtt_ws_05.webp 460w", "/en/static/d6a933c7963f40524dcbd9f46eadb419/966d8/rabbitmq_mqtt_ws_05.webp 920w", "/en/static/d6a933c7963f40524dcbd9f46eadb419/83135/rabbitmq_mqtt_ws_05.webp 1306w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d6a933c7963f40524dcbd9f46eadb419/81c8e/rabbitmq_mqtt_ws_05.png 230w", "/en/static/d6a933c7963f40524dcbd9f46eadb419/08a84/rabbitmq_mqtt_ws_05.png 460w", "/en/static/d6a933c7963f40524dcbd9f46eadb419/c0255/rabbitmq_mqtt_ws_05.png 920w", "/en/static/d6a933c7963f40524dcbd9f46eadb419/844cc/rabbitmq_mqtt_ws_05.png 1306w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d6a933c7963f40524dcbd9f46eadb419/c0255/rabbitmq_mqtt_ws_05.png",
              "alt": "RabbitMQ as MQTT Broker for your WQHD INSTAR Camera",
              "title": "RabbitMQ as MQTT Broker for your WQHD INSTAR Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      